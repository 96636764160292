<template>
<div class="tw-flex tw-flex-col tw-p-5" style="border-radius: 10px; background: #FDFFFC;" :style="{width: width}">
<div class="tw-flex tw-flex-row tw-justify-between tw-p-2">
  <label class="title" :style="{color:companyColor}">{{title}}</label>

</div>
  <div class="tw-flex tw-w-full ">
    <apexchart  type="donut" class="apex-style" :options="chartOptions" :series="series"  />

  </div>
</div>
</template>

<script>

import {getAllCompletedBookingsByTerminal} from "@/services/api/APIService";


export default {
  name: "AnalyticChart",
  components: {},
  props:{
    seriesData:[],
    xAxisData:[],
    width : [String],
    companyColor :{
      type : [String],
      default: "#004AAD"
    },
    title : [String],

  },
  data(){
    return {
      selectedFilter: "",
      series: [0, 1, 2, 3,  4  ],

      chartOptions: {
        chart: {
          type: 'donut',
          fontFamily : 'Inter, serif',

        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              }
            }
          }
          },
        labels: ['', '', '', '',''],
        dataLabels: {
          enabled: false
        },
        colors: ['#D0228E','#7CB0F6','#FFDD22','#004AAD','#C4C4C4'],
        responsive: [{
          breakpoint: 820,
          options: {
            chart: {
              width: 340
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  methods:{
   async getAllBookingsForTransportCompany(transportCompanyId) {
     let xaxis = []
     let yaxis = []
     let newArray = []
     await getAllCompletedBookingsByTerminal(transportCompanyId).then(res =>{
       Array.from(Object.entries(res.data), ([key, value]) => {
         newArray.push(value)
         return {[key]: value};
       });
       newArray.sort(function (a, b){
         return  b.totalBookings - a.totalBookings
       })
      const backToMap = new Map(
          newArray.map(object => {
            return [object.terminalName, object.totalBookings];
          }),
      )

       backToMap.forEach((value, key) => {
          let theKey = key.length > 6 ? key.substring(0,6) : key
          xaxis.push(theKey)
          yaxis.push(value)
       })
       if (yaxis.length >5){
         this.series = yaxis.slice(-5)
       }else {
         this.series = yaxis
       }
       if (xaxis.length > 5){
         this.chartOptions={...this.chartOptions,...{
             labels:xaxis.slice(-5)
           }}
       }
       else {
         this.chartOptions={...this.chartOptions,...{
             labels:xaxis
           }}
       }

      }) .catch((err) => {
        this.$displaySnackbar({
          message: err.response.data.details[0],
          success: false,
        });
      });
    }
  },
 async created() {
    let companyData = JSON.parse(sessionStorage.getItem('companyData'))
   await this.getAllBookingsForTransportCompany(companyData.id)
  }

}
</script>

<style scoped lang="scss">
.title{
  font-family: Inter,sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
}

.apex-style{
  width: 100% !important;
  height: auto !important;
  color: #19A2C7!important;

  @media screen and (max-width: 820px) {
    height: 8rem !important;
  }
}
</style>