<template>
<v-col sm="10" class="center-board">
<div  class="center-board-body lg:tw-p-10">
 <keep-alive>
   <router-view></router-view>
 </keep-alive>
</div>
</v-col>
</template>

<script>
import mainDashboard from "./MainDashboard";
import viewTerminalDashboardRoute from "./terminal/ViewTerminalDashboardRoute.vue"
import {mapGetters} from "vuex";
export default {
  name: "CenterDashboard",
  computed:{
    ...mapGetters("dashboardComponent", ["currentComponent"])
  },
  methods:{
    displayDashboardComponent(){
     let component = this.currentComponent
      if (component === "dashboard"){
        this.$router.push({name: 'MainDashboard'})
        return mainDashboard
      }
      else if (component === "terminals"){
        this.$router.push({name: 'Terminals'})
        return  viewTerminalDashboardRoute
      }
    }
  }
}
</script>

<style scoped lang="scss">
.center-board{
    display: flex;
    background: white;
    width: 100%;
    height: auto;
  padding: 0!important;
}
.center-board-body{
  display: flex;
  background: #F5F7F9;
  width: 97%;
  height: auto;
  @media screen and (max-width: 820px) {
    width: 100%;
    min-height: 100vh;
    height: max-content;
  }
}
</style>