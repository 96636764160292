<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />

    <v-col sm="12" v-else>
      <delete-modal
        :dialog="dialog"
        @close="closeModal"
        @deleteAction="deleteTerminal"
        :doneLoading="deleteLoading"
      />
      <button
        class="tw-flex tw-items-center tw-mt-6 overview-btn"
        @click="selectTab"
      >
        <!-- <img src="@/assets/icons/terminal-arrow-back.svg" alt="" /> -->
        <icons name="arrow-back" :color="companyColor" />
        <span class="tw-ml-4" :style="{ color: companyColor }"
          >Terminal View</span
        >
      </button>

      <v-col
        sm="12"
        class="tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full"
      >
        <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10">
          <p class="edit-header" :style="{ color: companyColor }">
            Edit Terminal
          </p>
          <button @click="openDeleteModal">
            <icons name="trash" :color="companyColor" />
          </button>

          <!-- <img src="@/assets/icons/del-blue.svg" alt="" /> -->
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(updateTerminal)">
            <v-row class="upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Upload Photo</p>
                  <p class="label-info">Choose an image as a profile</p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <button
                  class="button-logo-input"
                  type="button"
                  @click="onLogoFile()"
                  :class="image ? 'border-0' : ''"
                >
                  <v-progress-circular
                    :size="100"
                    :width="3"
                    :color="companyColor"
                    indeterminate
                    style="position: absolute;"
                    v-if="logoImageLoading"
                  ></v-progress-circular>

                  <img
                    :src="image"
                    alt=""
                    class="button-logo-img"
                    v-if="logoImage"
                  />
                  <img
                    :src="terminalData.terminalImage"
                    alt=""
                    class="button-logo-img"
                    v-else-if="terminalData.terminalImage"
                  />
                  <img
                    src="../../../assets/model.jpg"
                    alt=""
                    class="button-logo-img"
                    v-else
                  />
                </button>

                <input
                  type="file"
                  ref="logoImage"
                  accept="image/*"
                  style="display: none"
                  @change="onLogoImagePicked"
                />
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Terminal Name</p>
                  <p class="label-info">Type out the Name of the terminal</p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Terminal's Name"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-text-field
                      placeholder="Lekki Terminal"
                      v-model="terminalData.terminalName"
                      solo
                      hide-details
                      type="text"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm="12" lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Terminal ID</p>
                  <p class="label-info">
                    Type out the Identification Number of the Terminal
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Terminal's ID"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="" :class="classes">
                    <v-text-field
                      placeholder="7485738738"
                      v-model="terminalData.terminalId"
                      disabled
                      solo
                      hide-details
                      type="text"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>


            <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
              <v-col sm=12 lg="4" class="">
                <div class="form-label-div">
                  <p class="label">Address</p>
                  <p class="label-info">
                    Enter the Address in which the Terminal is located.
                  </p>
                </div>
              </v-col>
              <v-col sm="12" lg="8" class="">
                <ValidationProvider
                  name="Address"
                  rules="required"
                  v-slot="{ classes, errors }"
                >
                  <div class="tw-cursor-pointer" :class="classes" @click="handleShowAddressModal">
                    <v-text-field
                      placeholder="371,Borno Way, Alagomeji Yaba, Lagos"
                      solo
                      hide-details
                      type="text"
                      v-model="formattedAddress"
                      required
                    ></v-text-field>
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </v-col>
            </v-row>



            <div
              class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"
            >
              <v-btn
                text
                class="first-btn"
                :style="{
                  color: companyColor,
                  border: '1px solid' + companyColor,
                }"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                class="second-btn"
                type="submit"
                :style="{ background: companyColor }"
                :loading="doneLoading"
              >
                Update
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-col>
    </v-col>
    <modal section="custom-address"
           :address="terminalData.address"
           :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary,
  getTerminalById,
  deleteTerminal,
  updateTerminal,
  getLatLong,
} from "@/services/api/APIService";
import Icons from "../../reuseables/Icons";
import Loader from "../../reuseables/Loader";
import Lga from "../../../assets/lga";
import DeleteModal from "../../reuseables/DeleteModal";
import Modal from "@/components/reuseables/Modal.vue";
export default {
  name: "EditTerminal",
  components: {Modal, Loader, Icons, DeleteModal },

  data() {
    return {
      country: "",
      state: "",
      city: "",
      streetNumber: "",
      streetName: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      image: "",
      loading: false,
      doneLoading: false,
      companyData: {},
      terminalData: {
        address: {
          country: "",
          state: "",
          city: "",
          houseNumber: "",
          streetName:""
        },
      },
      companyColor: "#004AAD",
      terminalId: "",
      dialog: false,
      deleteLoading: false,
      showAddressModal: false,
      formattedAddress:""
    };
  },

  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
    getCities() {
      if (this.terminalData.address.country.toLowerCase() === "nigeria") {
        return Lga[this.terminalData.address.state];
      }
      return this.cities;
    },


  },
  methods: {
    getTerminalAddress(){
      if (this.terminalData.address.houseNumber) {
        return this.formattedAddress = this.toSentenceCase(this.terminalData.address.houseNumber) + " "
            +this.toSentenceCase(this.terminalData.address.streetName) +
            ", " + this.toSentenceCase(this.terminalData.address.city) + " "
            +this.toSentenceCase(this.terminalData.address.state)
            + " "+this.toSentenceCase(this.terminalData.address.country)
      }
      else {
        return this.formattedAddress =""
      }
    },
    handleShowAddressModal(){
      this.showAddressModal = true
    },

    closeHandleModal(){
      this.showAddressModal = !this.showAddressModal
    },

    handleAddress(address){
      console.log(address)
      this.terminalData.address = address
      this.getTerminalAddress()
    },

    toSentenceCase(text) {
      text = text.toLowerCase()
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
    },

    openDeleteModal() {
      console.log("click me");
      this.dialog = true;
    },
    async deleteTerminal() {
      this.deleteLoading = true;
      try {
        const response = await deleteTerminal(
          this.companyData.id,
          this.terminalId
        );
        console.log(response);
        this.$displaySnackbar({
          message: response.data.details,
          success: true,
        });
        this.deleteLoading = false;
        this.selectTab();
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
        this.deleteLoading = false;
        this.closeModal();

        console.log(error.response);
      }
    },
    closeModal() {
      this.dialog = !this.dialog;
      this.terminalId = "";
    },
    selectTab() {
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "allTerminals"
      );
      sessionStorage.setItem("terminalComponent", "allTerminals");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.terminalData.terminalImage = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
        this.logoImageLoading = false;
      }
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();

        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getStateChange() {
      this.terminalData.address.state = "";
      this.terminalData.address.city = "";
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.terminalData.address.country,
        };
        if (this.terminalData.address.country.name){
          data.country = this.terminalData.address.country.name
        }
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.terminalData.address.country,
        };
         await getAllStatesByCountry(data).then(res =>{
           this.states = res.data.data.states;
           if (this.terminalData.address.country.toLowerCase() === 'nigeria' ){
             this.states.push({name: 'Rivers State'})
           }
         })
          this.states.forEach(state => state.name === 'Lagos State' ? state.name = 'Lagos' : state.name)
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async getCity() {
      if (this.terminalData.address.country.name &&  this.terminalData.address.country.name.toLowerCase() === 'nigeria'){
          if (this.terminalData.address.state.name){
              this.cities = Lga[this.terminalData.address.state.name];
          }
          else {
              this.cities = Lga[this.terminalData.address.state];
          }
        console.log(this.cities, "all cities")
      }
      else  if (this.terminalData.address.country === 'Nigeria' ){
          if (this.terminalData.address.state.name){
              this.cities = Lga[this.terminalData.address.state.name];
          }
          else {
              this.cities = Lga[this.terminalData.address.state];
          }
          console.log(this.cities, "all cities")
      }
      else {
        try {
          this.getCitiesLoading = true;
          let data = {};
          if (this.terminalData.address.country.name){
              data.country=  this.terminalData.address.country.name
          }
          else {
              data.country = this.terminalData.address.country
          }
            if (this.terminalData.address.state.name){
                data.state=  this.terminalData.address.state.name
            }
            else {
                data.state = this.terminalData.address.state
            }

          const response = await getAllCitiesInAState(data);
          console.log(response);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },
    async getTerminalById() {
      try {
        const response = await getTerminalById(this.terminalId);
        response.data.address.state = this.toSentenceCase2(response.data.address.state)
        this.terminalData = await response.data;
        this.getTerminalAddress()
        await this.getState();
        await this.getCity();
        this.loading = false;
      } catch (err) {
        console.log(err.response);
        this.loading = false;
      }
    },
    toSentenceCase2(text){
       return  text.split(' ')
            .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
            .join(' ');
    },
    async updateTerminal() {
      this.doneLoading = true;
      await getLatLong(this.terminalData.address)
        .then(async (res) => {
          this.terminalData.latitude =
            res.data.results[0].geometry.location.lat;
          this.terminalData.longitude =
            res.data.results[0].geometry.location.lng;
          try {
            const response = await updateTerminal(
              this.terminalData,
              this.companyData.id,
              this.terminalId
            );
            console.log(response);
            this.$displaySnackbar({
              message: "Terminal Updated Successfully",
              success: true,
            });
            this.selectTab();
            this.doneLoading = false;
          } catch (error) {
            this.$displaySnackbar({
              message: error.response.data.details[0],
              success: false,
            });
            this.doneLoading = false;
            console.log(error.response);
          }
        })
        .catch((err) => {
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response,
            success: false,
          });
          this.doneLoading = false;
        });
    },
  },
  mounted() {
    this.getCountries();
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let slugId = sessionStorage.getItem("slugId");
    if (slugId) {
      this.terminalId = slugId;
    } else {
      return this.selectTab();
    }

    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getTerminalById();
  },
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter", serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
