<template>
<div class="tw-w-full tw-flex tw-flex-row header-div tw-items-center ">
  <div class="tw-w-full tw-flex tw-flex-row  tw-items-center" v-if="$vuetify.breakpoint.mdAndUp">


  <v-col sm="3" style="padding-left: 3rem">
    <img class="tw-cursor-pointer" :src="companyLogo ? companyLogo : 'https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png'"
         alt="company logo" style="width: auto; height: 30px"
         @click="$router.push({name:'MainDashboard'})" />
  </v-col>
  <v-icon :color="companyColor" size="24" class="pr-2">
    mdi-magnify
  </v-icon>
    <v-col sm="4" class="tw-flex tw-items-center tw-flex-row" style="border-right: 1px solid rgba(79, 79, 79, 0.06);
     border-left: 1px solid rgba(79, 79, 79, 0.06); height: 60px;">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field  placeholder=" " hide-details solo flat v-bind="attrs"
                        v-on="on" disabled />
        </template>
        <v-list>
          <v-list-item
              v-for="(item, index) in allThings"
              :key="index">
            <v-list-item-title style="color: black">{{ item}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


    </v-col>
  <v-icon :color="companyColor" size="24" class="px-5">
    mdi-bell-outline
  </v-icon>
  <v-col sm="3" class="tw-flex tw-items-center tw-flex-row" style="border-right: 1px solid rgba(79, 79, 79, 0.06);
     border-left: 1px solid rgba(79, 79, 79, 0.06); height: 60px;">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs"
             v-on="on" class="tw-flex tw-w-full tw-flex-row menu-drop-down" >
          <div class="tw-flex tw-items-start tw-justify-start tw-flex-col pl-5 tw-w-full" >
            <h6 class="company-name" :style="{color:companyColor? companyColor:'#004AAD'}">{{companyName}} <v-icon size="18">mdi-chevron-down</v-icon> </h6>
            <label class="company-email">{{companyEmail}}</label>
          </div>
        </div>
      </template>
      <v-list>
        <v-list-item @click="selectedAction(item)"
            v-for="(item, index) in items"
            :key="index" >
          <v-list-item-title style="color: black" >{{ item}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <avatar :avatar="companyName ?companyName.charAt(0):''" :color="companyColor" />
  </v-col>
  <v-col sm="1" class="tw-flex tw-items-center tw-justify-center">
    <v-btn text class="book" :style="{background:companyColor? companyColor:'#004AAD'}" @click="$router.push({name:'Booking'})">Book</v-btn>
  </v-col>
  </div>
  <div v-if="$vuetify.breakpoint.smAndDown" class="tw-flex tw-w-full tw-flex-col tw-px-4 tw-py-3 tw-bg-white tw-z-50"
       :class="[drawer ? '' : 'tw-fixed']">
    <div class="tw-flex tw-w-full tw-flex-row tw-justify-between tw-items-center">
      <img
          :src="companyLogo ? companyLogo : 'https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png'"
          alt="company logo"
          style="width: auto; height: 30px"
      />
      <div  class="tw-flex tw-items-center tw-justify-end">
        <v-btn v-if="$route.name !== 'Booking'" text class="book tw-mr-5" :style="{background:companyColor? companyColor:'#004AAD'}" @click="$router.push({name:'Booking'})">Book</v-btn>

      <v-icon  :color="companyColor" size="24" class="" @click="drawer = !drawer">
        mdi-view-dashboard-outline
      </v-icon>
      </div>
    </div>
    <v-navigation-drawer color="#FDFFFC" v-model="drawer"
                         absolute
                         temporary
                         right>

      <div class="tw-flex tw-px-5 tw-pt-5 tw-pb-2 tw-w-full tw-justify-end" style="background: #FDFFFC">
        <v-icon :color="companyColor" @click="drawer = !drawer">mdi-window-close</v-icon>
      </div>

      <v-col
          class="tw-flex tw-items-center tw-flex-row visible mb-5"
          style="
            border-right: 1px solid rgba(79, 79, 79, 0.06);
            border-left: 1px solid rgba(79, 79, 79, 0.06);
            height: 60px;
          "
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                class="tw-flex tw-w-full tw-flex-row menu-drop-down"
            >
              <div
                  class="
                    tw-flex tw-items-start tw-justify-start tw-flex-col
                    pl-5
                    tw-w-full
                  "
              >
                <h6
                    class="company-name"
                    :style="{ color: companyColor ? companyColor : '#004AAD' }"
                >
                  {{ companyData.companyName }}
                  <v-icon size="18">mdi-chevron-down</v-icon>
                </h6>
                <label class="company-email">{{ companyData.companyEmail }}</label>
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item
                @click="selectedAction(item)"
                v-for="(item, index) in items"
                :key="index"
            >
              <v-list-item-title style="color: black">{{
                  item
                }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <image-avatar :avatar="companyData.companyName ? companyData.companyName.charAt(0) : ''"
                      :color="companyColor" :image="companyData.companyLogo" />
      </v-col>

      <mobile-side-bar :company-color="companyColor"/>
    </v-navigation-drawer>
  </div>
</div>
</template>

<script>
import Avatar from "../reuseables/Avatar";
import {logout} from "@/services/api/AuthApiService";
import ImageAvatar from "@/components/reuseables/ImageAvatar.vue";
import {mapGetters} from "vuex";
import MobileSideBar from "@/components/transportCompany/MobileSideBar.vue";
export default {
  name: "TransportCompanyDashboardHeader",
  components: {MobileSideBar, ImageAvatar, Avatar},
  props:{
    companyColor:{
      type: [String],
      default: "#004AAD"
    },
    companyEmail: [String],
    companyName : [String],
    companyLogo: [String]
  },
  data(){
    return{
      items:["Profile","Logout"],
      allThings:[],
      drawer : false
    }
  },
  computed:{
    ...mapGetters("companyProfile", ["companyData"])
  },
  methods:{
    selectedAction(item){
      if (item === 'Logout'){
        this.handleLogout()
      }
      else if (item === 'Profile'){
        sessionStorage.setItem("dashboardComponent", "settings");
        this.$store.dispatch("dashboardComponent/setCurrentComponent", "settings");
        this.$router.push({name: 'Settings'})

        window.location.reload()
      }
    },
    handleLogout() {
      logout()
    }
  },
  watch:{
    drawer: function (val) {
      if (val === true){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    immediate: true
  },
}
</script>

<style scoped lang="scss">
.header-div{
  background-color: #FFFFFF;
  height: 60px;
}

.book{
  border-radius: 6px;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  width: auto;

}

.company-name{
  font-family: Inter,serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.company-email{
  font-family: Inter,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 10px;
  color: #BDBDBD;
  @media screen and (min-width: 1900px){
    font-size: 12px;
  }
}
.menu-drop-down{
  width: 60%;
  @media screen and (max-width: 11450px){
    width: 80%;
  }
}
</style>