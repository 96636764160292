<template>
  <v-col sm="12">
    <button
      class="tw-flex tw-items-center tw-mt-6 overview-btn"
      @click="routeBack"
      :style="{ color: companyColor }"
    >
      <icons name="arrow-back" :color="companyColor" />
      <span class="tw-ml-4">All Terminal</span>
    </button>

    <v-col
      sm="12"
      class="
        tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full
      "
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addTerminal)">
          <div
            class="
              tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10
            "
          >
            <p class="edit-header" :style="{ color: companyColor }">
              New Terminal
            </p>
            <icons name="trash" :color="companyColor" v-if="id" />
          </div>
          <v-row class="upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Upload Photo</p>
                <p class="label-info">Choose an image as a terminal image</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <button
                class="button-logo-input"
                type="button"
                @click="onLogoFile()"
                :class="image ? 'border-0' : ''"
              >
                <v-progress-circular
                  :size="100"
                  :width="3"
                  :color="companyColor"
                  indeterminate
                  style="position: absolute"
                  v-if="logoImageLoading"
                ></v-progress-circular>

                <img
                  :src="image"
                  alt=""
                  class="button-logo-img"
                  v-if="logoImage"
                />
                <img
                  src="../../../assets/camera.png"
                  alt=""
                  class="button-logo-input-img"
                  v-else
                />
              </button>

              <input
                type="file"
                ref="logoImage"
                accept="image/*"
                style="display: none"
                @change="onLogoImagePicked"
              />
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Terminal Name</p>
                <p class="label-info">Type out the Name of the terminal</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Terminal's Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                    placeholder="Lekki Terminal"
                    solo
                    hide-details
                    type="text"
                    v-model="terminalData.terminalName"
                    required
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>


          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Address</p>
                <p class="label-info">
                  Enter the Address in which the Terminal is located.
                </p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Address"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="tw-cursor-pointer" :class="classes" @click="handleShowAddressModal">
                  <v-text-field
                    placeholder="371,Borno Way, Alagomeji Yaba, Lagos"
                    solo
                    hide-details
                    type="text"
                    v-model="formattedAddress"
                    required
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <div
            class="
              tw-flex tw-w-full tw-justify-end tw-items-center
              action-btn-div
              tw-mt-10
            "
          >
            <v-btn
              @click="routeBack"
              text
              class="first-btn"
              :style="{
                color: companyColor,
                border: '1px solid' + companyColor,
              }"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              class="second-btn"
              type="submit"
              :style="{ background: companyColor }"
              :loading="doneLoading"
            >
              Done
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-col>
    <modal section="custom-address"
           :address="terminalData.address"
           :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary,
  getLatLong,
  addTerminal,
} from "@/services/api/APIService";
import Icons from "../../reuseables/Icons";
import Lga from "../../../assets/lga";
import Modal from "@/components/reuseables/Modal.vue";

export default {
  name: "AddTerminal",
  components: {Modal, Icons },
  props: {},
  data() {
    return {
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      logoImage: "",

      logoImageLoading: false,
      image: "",
      companyColor: "#004AAD",
      id: null,
      companyData: {},
      doneLoading: false,
      terminalData: {
        terminalStreetName: "",
        terminalName: "",
        terminalImageUrl: "",
        address: {
          country: "",
          state: "",
          city: "",
          houseNumber: "",
          streetName:""
        },
      },
      formattedAddress : "",
      showAddressModal: false,
    };
  },
  watch: {
    terminalData: {
      handler: function (val) {
        if (val) {
          val.transportCompanyId = this.companyData.id;
          this.$store.dispatch("guideData/setGuide", val);
        }
      },
      immediate: true,
    },
  },
  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },
    getCities() {
      if (this.country.name.toLowerCase() === "nigeria") {
        return Lga[this.state.name];
      }
      return this.cities;
    },


  },
  methods: {
    handleShowAddressModal(){
      this.showAddressModal = true
    },
    getTerminalAddress(){
      if (this.terminalData.address.houseNumber) {
        return this.formattedAddress = this.toSentenceCase(this.terminalData.address.houseNumber) + " "
            +this.toSentenceCase(this.terminalData.address.streetName) +
            ", " + this.toSentenceCase(this.terminalData.address.city) + " "
            +this.toSentenceCase(this.terminalData.address.state)
            + " "+this.toSentenceCase(this.terminalData.address.country)
      }
      else {
        return  this.formattedAddress =""
      }
    },

    closeHandleModal(){
      this.showAddressModal = !this.showAddressModal
    },

    handleAddress(address){
      console.log(address)
      this.terminalData.address = address
      this.getTerminalAddress()
    },

    toSentenceCase(text) {
      text = text.toLowerCase()
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
    },

    routeBack() {
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "allTerminals"
      );
      sessionStorage.setItem("terminalComponent", "allTerminals");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.terminalData.terminalImageUrl = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        console.log(response);
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.terminalData.country.name,
        };
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        if (this.terminalData.country.name.toLowerCase() === "nigeria") {
          this.states.push({ name: "Rivers State" });
        }
        this.states.forEach(state => state.name === 'Lagos State' ? state.name = 'Lagos' : state.name)
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      if (this.terminalData.country.name.toLowerCase() === "nigeria") {
        this.cities = Lga[this.terminalData.state.name];
      } else {
        try {
          this.getCitiesLoading = true;
          let data = {
            country: this.terminalData.country.name,
            state: this.terminalData.state.name,
          };
          const response = await getAllCitiesInAState(data);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },

    async addTerminal() {
      this.doneLoading = true;
      let data = {};
      data.transportCompanyId = this.companyData.id;
      data.terminalName = this.terminalData.terminalName;
      data.terminalImage = this.terminalData.terminalImageUrl;
      data.address = this.terminalData.address;
      await getLatLong(this.terminalData.address)
        .then(async (res) => {
          data.latitude = res.data.results[0].geometry.location.lat;
          data.longitude = res.data.results[0].geometry.location.lng;

          await addTerminal(data)
            .then(() => {
              this.clearData();

                this.routeBack();

              this.doneLoading = false;
            })
            .catch((err) => {
              console.log(err.response);
              this.$displaySnackbar({
                message: err.response.data.details[0],
                success: false,
              });
              this.doneLoading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
          this.doneLoading = false;
        });
    },
    clearData() {
      this.image = "";
      this.terminalData.terminalName = "";
      this.terminalData.terminalHouseNumber = "";
      this.terminalData.terminalStreetName = "";
      this.terminalData.city = "";
      this.terminalData.state = "";
      this.terminalData.country = "";
    },
  },
  mounted() {
    this.getCountries();
  },
  created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    console.log(this.companyData);
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
  },
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 10px;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  //border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter", serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: "Inter", serif !important;
    text-transform: none;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
    font-family: "Inter", serif !important;
    text-transform: none;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  //color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  //color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter", serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
