<template>
  <div class="tw-w-full">
    <loader :loading="loading" v-if="loading"/>

<div class="dashboard" v-else>
  <transport-company-dashboard-header :company-color="companyData.companyColor"
                                      :company-name="companyData.companyName"
                                      :company-email="companyData.companyEmail"
                                      :company-logo="companyData.companyLogo"/>
  <v-col sm="12" class="tw-flex tw-w-full tw-justify-between  dashboard-body" style="background: #F5F7F9">
    <side-menu :company-color="companyData.companyColor"/>
    <center-dashboard />
  </v-col>
</div>
  </div>
</template>

<script>
import TransportCompanyDashboardHeader from "../components/transportCompany/TransportCompanyDashboardHeader";
import SideMenu from "../components/transportCompany/SideMenu";
import CenterDashboard from "../components/transportCompany/CenterDashboard";
import {mapGetters} from "vuex";
import Loader from "@/components/reuseables/Loader";

export default {
  name: "TransportCompanyDashboard",
  components: {Loader, CenterDashboard, SideMenu, TransportCompanyDashboardHeader},
  data(){
    return{
      loading : false
    }
  },

  computed:{
    ...mapGetters("companyProfile",["companyData"])
  },
 async created() {
    this.loading = true
    let userData = JSON.parse(sessionStorage.getItem("userData"))
    if (!userData.email){
      this.loading = false
     await this.$router.push({name : "LandingPageView"})
    }
    else {
      if (userData.firstLogin){
        this.loading = false
       await this.$router.push({name: "OnboardingIdentity"})
      }
    }
    let data = {}
    data.companyEmail = userData.email
   await this.$store.dispatch('companyProfile/setCompanyData',data).then(() => this.loading = false)
  }
}
</script>

<style scoped lang="scss">
.dashboard{
  display: flex;
  background-color: #F5F7F9;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.dashboard-body{
  display: flex;
  min-height: 93.7vh;
  flex-direction: row;
  padding: 0 !important;
  @media screen and (max-width: 1450px){
    min-height: 93vh;
  }
  @media screen and (max-width: 1370px){
    min-height: 87vh;
  }
}
</style>