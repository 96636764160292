import { render, staticRenderFns } from "./RevenueChart.vue?vue&type=template&id=3b80a29d&scoped=true&"
import script from "./RevenueChart.vue?vue&type=script&lang=js&"
export * from "./RevenueChart.vue?vue&type=script&lang=js&"
import style0 from "./RevenueChart.vue?vue&type=style&index=0&id=3b80a29d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b80a29d",
  null
  
)

export default component.exports