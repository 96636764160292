<template>
  <!-- <router-view ></router-view> -->
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script >
import TerminalDashboard from './ViewTerminalDashboard.vue'
import AllTerminals from './AllTerminalDashboard.vue'
import SingleDashboard from './SingleTerminal.vue'
import EditTerminal from './EditTerminal'
import AddTerminal from './AddTerminal'
import {mapGetters} from 'vuex'

export default {
  name: 'ViewTerminalDashboardRoute',
  computed: {
    ...mapGetters( "terminalComponent", ["currentComponent"] )
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent
      if (component === "terminalDashboard") {
        return TerminalDashboard
      } else if (component === "allTerminals") {
        return AllTerminals
      } else if (component === 'singleTerminal') {
        return SingleDashboard
      } else if (component === 'editTerminal') {
        return EditTerminal
      } else if (component === 'addTerminal') {
        return AddTerminal
      }
    }
  },
  created() {
    let component = sessionStorage.getItem('terminalComponent')

    if (!component) {
      sessionStorage.setItem('terminalComponent', "terminalDashboard")
      this.$store.dispatch("terminalComponent/setCurrentComponent", "terminalDashboard");
      this.displayTerminalComponent()


    } else {
      this.$store.dispatch('terminalComponent/setCurrentComponent', component)
      this.displayTerminalComponent()
    }
  }

}

</script>

<style lang="scss" scoped>

</style>