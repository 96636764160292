var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[(_vm.loading)?_c('loader',{attrs:{"color":_vm.companyColor}}):_c('v-col',{attrs:{"sm":"12"}},[_c('delete-modal',{attrs:{"dialog":_vm.dialog,"doneLoading":_vm.deleteLoading},on:{"close":_vm.closeModal,"deleteAction":_vm.deleteTerminal}}),_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",on:{"click":_vm.selectTab}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyColor}}),_c('span',{staticClass:"tw-ml-4",style:({ color: _vm.companyColor })},[_vm._v("Terminal View")])],1),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full",attrs:{"sm":"12"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyColor })},[_vm._v(" Edit Terminal ")]),_c('button',{on:{"click":_vm.openDeleteModal}},[_c('icons',{attrs:{"name":"trash","color":_vm.companyColor}})],1)]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateTerminal)}}},[_c('v-row',{staticClass:"upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Upload Photo")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose an image as a profile")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.companyColor,"indeterminate":""}}):_vm._e(),(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):(_vm.terminalData.terminalImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.terminalData.terminalImage,"alt":""}}):_c('img',{staticClass:"button-logo-img",attrs:{"src":require("../../../assets/model.jpg"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})])],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Terminal Name")]),_c('p',{staticClass:"label-info"},[_vm._v("Type out the Name of the terminal")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Terminal's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Lekki Terminal","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.terminalData.terminalName),callback:function ($$v) {_vm.$set(_vm.terminalData, "terminalName", $$v)},expression:"terminalData.terminalName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Terminal ID")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type out the Identification Number of the Terminal ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Terminal's ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"7485738738","disabled":"","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.terminalData.terminalId),callback:function ($$v) {_vm.$set(_vm.terminalData, "terminalId", $$v)},expression:"terminalData.terminalId"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Address")]),_c('p',{staticClass:"label-info"},[_vm._v(" Enter the Address in which the Terminal is located. ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-cursor-pointer",class:classes,on:{"click":_vm.handleShowAddressModal}},[_c('v-text-field',{attrs:{"placeholder":"371,Borno Way, Alagomeji Yaba, Lagos","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.formattedAddress),callback:function ($$v) {_vm.formattedAddress=$$v},expression:"formattedAddress"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"},[_c('v-btn',{staticClass:"first-btn",style:({
                color: _vm.companyColor,
                border: '1px solid' + _vm.companyColor,
              }),attrs:{"text":""}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyColor }),attrs:{"text":"","type":"submit","loading":_vm.doneLoading}},[_vm._v(" Update ")])],1)],1)]}}])})],1)],1),_c('modal',{attrs:{"section":"custom-address","address":_vm.terminalData.address,"dialog":_vm.showAddressModal},on:{"handleAddress":_vm.handleAddress,"close":_vm.closeHandleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }