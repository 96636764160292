<template>
  <v-col>
    <loader :color="companyColor" v-if="loading" />

    <v-col sm="12" class="main-body" v-else>
      <delete-modal
        :dialog="dialog"
        @close="closeModal"
        @deleteAction="deleteTerminal"
        :doneLoading="deleteLoading"
      />
      <v-col sm="12" class=" tw-flex tw-justify-between tw-items-center">
        <button
          class="tw-flex tw-items-center tw-mt-6 overview-btn"
          @click="backToTerminals"
        >
          <icons name="arrow-back" :color="companyColor" />

          <span class="tw-ml-4" :style="{ color: companyColor }"
            >All Terminals</span
          >
        </button>
        <div class="tw-flex tw-items-center">
          <button @click="selectEditTab(terminalData.id)">
            <icons name="edit" :color="companyColor" />
          </button>
          <button class="tw-pl-5" @click="openDeleteModal">
            <icons name="trash" :color="companyColor" />
          </button>
        </div>
      </v-col>

      <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-8 lg:tw-px-16">
        <v-col sm="12" class="terminal-img-container">
          <img
            :src="terminalData.terminalImage"
            :alt="terminalData.terminalName"
            v-if="terminalData.terminalImage"
          />
        </v-col>
        <v-row class="tw-mt-6 lg:tw-px-12 tw-hidden lg:tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between">
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0">Name</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
                terminalData.terminalName
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0">Town/City</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
                terminalData.address.city
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0">Terminal ID</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
                terminalData.terminalId
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0">State</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
                terminalData.address.state
              }}</v-col>
            </div>
          </v-col>

          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0">Street No.</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0">{{
                terminalData.address.houseNumber
              }}</v-col>
            </div>
          </v-col>

          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0">Country</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
                terminalData.address.country
              }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0">Street Name</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
                terminalData.address.streetName
              }}</v-col>
            </div>
          </v-col>
        </v-row>
        <div class="tw-flex tw-w-full tw-flex-col lg:tw-hidden tw-mt-5">
          <v-col sm="12" lg="6" class="label-key ">Name</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              terminalData.terminalName
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key tw-m-0">Terminal ID</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              terminalData.terminalId
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key tw-m-0">Street No.</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0">{{
              terminalData.address.houseNumber
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key tw-m-0">Street Name</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              terminalData.address.streetName
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key tw-m-0">Town/City</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              terminalData.address.city
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key tw-m-0">State</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              terminalData.address.state
            }}</v-col>
          <v-col sm="12" lg="6" class="label-key tw-m-0">Country</v-col>
          <v-col sm="12" lg="6" class="label-value tw-m-0 text-capitalize">{{
              terminalData.address.country
            }}</v-col>
        </div>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import { getTerminalById, deleteTerminal } from "@/services/api/APIService";
import Icons from "../../reuseables/Icons";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
export default {
  name: "SingleTerminal",
  components: { Loader, Icons, DeleteModal },
  data() {
    return {
      terminalId: "",
      companyData: {},
      terminalData: {},
      companyColor: "#004AAD",
      loading: false,
      dialog: false,
      deleteLoading: false,
    };
  },
  methods: {
    openDeleteModal() {
      console.log("click me");
      this.dialog = true;
    },
    async deleteTerminal() {
      this.deleteLoading = true;
      try {
        const response = await deleteTerminal(
          this.companyData.id,
          this.terminalData.id
        );
        console.log(response);
        this.$displaySnackbar({
          message: response.data.details,
          success: true,
        });
        this.deleteLoading = false;
        this.backToTerminals();
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
        this.deleteLoading = false;
        this.closeModal();

        console.log(error.response);
      }
    },
    closeModal() {
      this.dialog = !this.dialog;
      this.terminalId = "";
    },
    backToTerminals() {
      // console.log("i'm here");
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "allTerminals"
      );
      sessionStorage.setItem("terminalComponent", "allTerminals");
    },
    selectEditTab(id) {
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "editTerminal"
      );
      sessionStorage.setItem("terminalComponent", "editTerminal");
      sessionStorage.setItem("slugId", id);
    },
    async getTerminalById() {
      try {
        const response = await getTerminalById(this.terminalId);
        this.terminalData = await response.data;
        this.loading = false;
      } catch (err) {
        console.log(err.response);
        this.loading = false;
      }
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    let slugId = sessionStorage.getItem("slugId");
    if (slugId) {
      this.terminalId = slugId;
    } else {
      return this.backToTerminals();
    }

    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getTerminalById();
  },
};
</script>

<style lang="scss" scoped>
.label-key {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.terminal-img-container {
  width: 100%;
  height: 255px;
  img {
    width: auto;
    height: 255px;
    border-radius: 4px;
  }
}
.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
/* .main-body{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0!important;
} */
.view-link {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: rgba(0, 74, 173, 1);
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.card-div {
  img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .header-id {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
}

.img-div {
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .no-image {
    /* background: rgba(124, 176, 246, 1); */
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 1);

    p {
      font-family: "Inter";
      font-size: 50px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      margin-bottom: 0 !important;
      color: rgba(255, 255, 255, 1);

      /* text-align: left; */
    }
  }
  .header {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header-id {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  padding: 0;
  padding-left: 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0;
  padding-left: 5px;
}
</style>
