<template>
<div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-my-2 ml-2 tw-py-5" style="height: 84px;
box-shadow: 0px 2px 20px rgba(79, 79, 79, 0.1);
border-radius: 10px;">
<div class="bus-img-div">
  <img :src="trip.fleetForThisTrip.vehicleImage" alt="bus" class="bus-img">
</div>
<div class="tw-flex tw-flex-col tw-items-center tw-justify-center" style="width: 50%">
  <h6 class="trip-id">{{trip.fleetForThisTrip.vehicleTagNumber}}</h6>
  <div class="tw-flex tw-w-full tw-flex-row tw-justify-center tw-pt-2">
    <label class="trip-detail">{{getTime(trip.takeOffDate,trip.takeOffTime)}} {{trip.routeDepartureCity}}
      <span class="px-1"><img src="@/assets/to.svg"></span> {{trip.routeDestinationCity}}</label>
  </div>
</div>
</div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "TripCard",
  props:{
    trip: Object
  },
  methods:{
    getTime(date,time){
      return dayjs(date+time).format('LT')
    }
  }
}
</script>

<style scoped lang="scss">
.bus-img{
  display: flex;
  width: 48px;
  height: 48px;

}
.bus-img-div{
  display: flex;
  width: 20%;
  height: auto;
  border-right: 1px dashed rgba(187, 187, 187, 0.21);
  justify-content: center;
}
.trip-id{
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}
.trip-detail{
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 8px !important;
  line-height: 10px;
  color: #4F4F4F;
  display: flex;
}
</style>