<template>
  <v-col sm="12">
    <loader :color="getColor" v-if="loading" :loading="loading" />
    <v-col sm="12" class="main-body" v-else>

      <v-col sm="12" lg="8" class="tw-p-0 tw-flex-col">
        <div class="tw-pb-3">
        <update-account-banner/>
        </div>
        <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between tw-mb-10">
          <icon-and-text
            :icon="read"
            :title="totalBookings ? totalBookings.toString() : 'Nil'"
            desc="Bookings"
            :width="$vuetify.breakpoint.mdAndUp ? '24%': '100%'"
          />
          <icon-and-text
            :icon="timer"
            :title="totalTrips ? totalTrips.toString() : 'Nil'"
            desc="Total trips"
            :width="$vuetify.breakpoint.mdAndUp ? '24%': '100%'"
          />
          <icon-and-text
            :icon="cash"
            :title="totalTerminals ? totalTerminals.toString(): 'Nil'"
            desc="Total terminal"
            :width="$vuetify.breakpoint.mdAndUp ? '24%': '100%'"
          />
            <image-and-text
            :img="locationImg"
            title="Lagos"
            desc="Top Destination"
            :width="$vuetify.breakpoint.mdAndUp ? '24%': '100%'"
          />
        </div>

        <div v-if="!totalBookings" class="tw-flex tw-w-full">
          <div class="tw-flex tw-w-full tw-flex-col tw-items-center">
            <img
              :src="emptyDashboard"
              alt="empty"
              class="tw-mt-5"
              style="width: 100%; height: auto"
            />
            <label class="empty-title tw-mt-5">Nothing to see here.</label>
            <label class="empty-desc"
              >You can start by
              <span
                :style="{ color: companyData.companyColor }"
                class="tw-cursor-pointer"
                @click="routeToTerminal"
                >adding a Terminal</span
              ></label
            >
          </div>
<!--          <intro @started="closeFirstGuide" v-if="!companyData.guideCompleted" :company-color="getColor" :show-guide="firstGuide" />-->
<!--          <Steps  :company-color="getColor" :show-guide="secondGuide" />-->
        </div>
        <div v-else class="tw-flex tw-w-full tw-flex-col tw-mb-10 lg:tw-mb-0">
          <div
            class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between tw-mb-10"
            :style="{height: $vuetify.breakpoint.mdAndUp ? '310px' : 'auto'}"
          >
            <spline-chart
              chart-type="area"
              title="Bookings"
              :width="$vuetify.breakpoint.mdAndUp ? '58%': '100%'"
              :company-color="getColor"
              class="tw-mb-10 lg:tw-mb-0"
            />
            <pie-chart
              title="Top 5 Terminal by Bookings"
              :width="$vuetify.breakpoint.mdAndUp ? '40%': '100%'"
              :company-color="getColor"
            />
          </div>
          <div
            class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-justify-between tw-mb-2"
            :style="{height: $vuetify.breakpoint.mdAndUp ? '310px' : 'auto'}"
          >
            <revenue-chart
              title="Revenue"
              :width="$vuetify.breakpoint.mdAndUp ? '40%': '100%'"
              :company-color="getColor"
              class="tw-mb-10 lg:tw-mb-0"
            />
            <trip-chart
              title="Trips"
              :width="$vuetify.breakpoint.mdAndUp ? '58%': '100%'"
              chart-type="area"
              :company-color="getColor"
            />
          </div>
        </div>
      </v-col>
      <v-col sm="12" lg="4" class="tw-p-0">
        <div
          class="tw-flex tw-w-full lg:tw-w-11/12 lg:tw-ml-8 tw-flex-col"
          style="height: 99%; background: #fdfffc; border-radius: 10px"
        >
          <v-date-picker
            no-title
            flat
            elevation="0"
            class="date"
            style="
              border: none !important;
              background: #fdfffc !important;
              margin-top: 1rem;
            "
            full-width
            :color="getColor"
            :style="{ color: getColor }"
            v-model="selectedDate"
            :header-color="getColor"
            :event-color="getColor"
            :min="minDate"
          />
          <div class="tw-flex tw-px-8 tw-flex-col">
            <v-divider />
            <h6 :style="{ color: getColor }" class="pending-trips tw-py-8">
              Pending Trips
            </h6>
            <label class="current-date tw-pb-8">{{
              formatDate(selectedDate)
            }}</label>
            <div class="tw-flex tw-w-full tw-flex-col scroll">
              <label
                class="current-date"
                v-if="getSelectedDateTrip && !getSelectedDateTrip.length"
                >No Trip</label
              >
              <div v-else>
                <trip-card
                  v-for="(trip, index) in getSelectedDateTrip"
                  :trip="trip"
                  :key="index"
                />
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import IconAndText from "./IconAndText";
import read from "@/assets/icons/read.svg";
import timer from "@/assets/icons/timer.svg";
import cash from "@/assets/icons/terminal-cash.svg";
import emptyDashboard from "@/assets/empty-dashboard.svg";
import locationImg from "@/assets/location.png";
import ImageAndText from "./ImageAndText";
import SplineChart from "./BookingChart";
import PieChart from "./AnalyticChart";
import RevenueChart from "./RevenueChart";
import TripChart from "./TripChart";
import dayjs from "dayjs";
import TripCard from "./TripCard";
import { mapGetters } from "vuex";
// import Intro from "./guides/Intro.vue";
// import Steps from "./guides/Steps.vue";
import {getAllPendingTripsByPage, getAllTransportCompanyBookingCount,
  getTotalTripCountByTransportCompany,
  getTransportCompanyRevenue, getTransportCompanyTerminalCount,
} from "@/services/api/APIService";
import Loader from "../reuseables/Loader";
import UpdateAccountBanner from "../reuseables/UpdateAccountBanner.vue";
// import Intro from "@/components/transportCompany/guides/Intro";
export default {
  name: "MainDashboard",
  components: {
    UpdateAccountBanner,
    // Intro,
    Loader,
    // Steps,

    TripCard,
    TripChart,
    RevenueChart,
    PieChart,
    SplineChart,
    ImageAndText,
    IconAndText,
  },
  data() {
    return {
      read,
      timer,
      cash,
      emptyDashboard,
      locationImg,
      selectedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      minDate: new Date(new Date().getTime() + 60 * 60 * 1000)
        .toISOString()
        .substr(0, 10),
      pendingTrips: [],
      empty: false,
      firstGuide : true,
      secondGuide: false,
      totalBookings: null,
      totalTrips: null,
      totalRevenue: null,
      totalTerminals  : null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("companyProfile", ["companyData"]),

    getColor() {
      let data = JSON.parse(sessionStorage.getItem("companyData"));
      let color = "#0044AD";
      if (data) {
        color = data.companyColor;
      }
      return color;
    },
    getSelectedDateTrip() {
      return this.pendingTrips.filter(
        (trip) => trip.takeOffDate === this.selectedDate
      );
    },
  },
  methods: {
    closeFirstGuide() {
      this.firstGuide = false;
      this.secondGuide = true;
    },
    formatDate(date) {
      return dayjs(date).format("LL");
    },
    routeToTerminal() {
      this.$store.dispatch(
        "dashboardComponent/setCurrentComponent",
        "terminals"
      );
      sessionStorage.setItem("dashboardComponent", "terminals");
      this.$router.push({ name: "Terminals" });
      location.reload();
    },
    async getTransportCompanyBookings() {
      let data = JSON.parse(sessionStorage.getItem("companyData"));
      if (data) {
        await getAllTransportCompanyBookingCount(data.id)
          .then((res) => {
            this.totalBookings = res.data.totalBooking;
          })
          .catch((err) => {
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
      }
    },
    async getTransportCompanyTrips() {
      let data = JSON.parse(sessionStorage.getItem("companyData"));
      if (data) {
        await getTotalTripCountByTransportCompany(data.id)
          .then((res) => {
            this.totalTrips = res.data.totalTrip;
          })
          .catch((err) => {
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
      }
    },
    async getTransportCompanyPendingTrips() {
      let data = JSON.parse(sessionStorage.getItem("companyData"));
      if (data) {
        let pageData = {
          transportCompanyId : data.id,
          page : 0,
          pageSize: 10
        }
        await getAllPendingTripsByPage(pageData).then((res) => {
          this.pendingTrips = res.data;
        });
      }
    },
    async getTransportCompanyRevenue() {
      let data = JSON.parse(sessionStorage.getItem("companyData"));
      if (data) {
        await getTransportCompanyRevenue(data.id)
          .then((res) => {
            this.totalRevenue = res.data / process.env.VUE_APP_KOBO;
          })
          .catch((err) => {
            console.log(err.response);
            this.$displaySnackbar({
              message: err.response.data.details[0],
              success: false,
            });
          });
      }
    },
    async getAllTerminals() {
        let data = JSON.parse(sessionStorage.getItem("companyData"));
        if (data) {
            await getTransportCompanyTerminalCount(data.id)
                .then((res) => {
                    this.totalTerminals = res.data.totalTerminal
                })
                .catch((err) => {
                    console.log(err.response);
                    this.$displaySnackbar({
                        message: err.response.data.details[0],
                        success: false,
                    });
                });
        }
    }

  },

  async beforeMount() {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    let data = {}
    data.companyEmail = userData.email
    await this.$store.dispatch("companyProfile/setCompanyData", data);
    sessionStorage.setItem("companyData", JSON.stringify(this.companyData));
    let isFirst = sessionStorage.getItem("isFirst");
    if (!isFirst) {
      this.$nextTick(()=>{
        this.$router.push({name:'TransportCompanyDashboard'})
      })
      sessionStorage.setItem("isFirst", "true");
    }
  },
  async created() {
    this.loading = true;
    await this.getAllTerminals();
    await this.getTransportCompanyBookings();
    await this.getTransportCompanyTrips();
    // await this.getTransportCompanyRevenue();
    await this.getTransportCompanyPendingTrips();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.main-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding: 0 !important;

  @media screen and (max-width: 820px){
    flex-direction: column;
  }
}

.empty-title {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #878787;
}
.empty-desc {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #878787;
}

.date {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 22px;
  //color: #004AAC;
}
.pending-trips {
  font-family: Inter, serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
}
.current-date {
  font-family: Inter, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #4f4f4f;
}

.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 35vh;
  max-height: 35vh;
  padding-bottom: 1rem;
}
.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}
.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}
</style>