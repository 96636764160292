<template>
  <v-col sm="2" class="side-menu tw-mt-10" v-if="$vuetify.breakpoint.mdAndUp">
    <div class="tw-flex tw-flex-col tw-w-full side-menu-max">
    <div
      :class="selectedTab === tab ? 'selected' : 'select-div'"
      class="my-3 ml-9 tw-cursor-pointer"
      @click="handleSelectedTab(tab)"
      v-for="(tab, index) in tabs"
      :key="index"
    >
      <v-icon v-if="tab === selectedTab" :color="companyColor"
        >mdi-power-on</v-icon
      >
      <icons
        :name="tab"
        :color="selectedTab === tab ? companyColor : '#4F4F4F'"
      />
      <label
        class="pl-4 text-capitalize"
        :style="{ color: selectedTab === tab ? companyColor : '#4F4F4F' }"
        >{{ tab }}</label
      >
    </div>
    <div
      class="
        tw-flex tw-items-center tw-flex-row ml-9
        tw-mt-10 tw-cursor-pointer "
      @click="handleLogout">
      <icons :color="companyColor" name="logout" />
      <span class="pl-4 text-capitalize" :style="{ color: companyColor }"
        >logout</span
      >
    </div>
    </div>
  </v-col>
</template>

<script>
import Icons from "../reuseables/Icons";
import { mapGetters } from "vuex";
import { logout } from "@/services/api/AuthApiService";
export default {
  name: "SideMenu",
  components: { Icons },
  props: {
    companyColor: {
      type: [String],
      default: "#004AAD",
    },
  },
  data() {
    return {
      selectedTab: "dashboard",
      tabs: [
        "dashboard",
        "booking",
        "manifest",
        "terminals",
        "fleet",
        "leasing",
        "managers",
        "booking admins",
        "pilots",
        "routes",
        "trips",
        "settings",
        "wallet"
      ],
    };
  },
  computed: {
    ...mapGetters("dashboardComponent", ["currentComponent"]),
    routeName() {
      return this.$route.name;
    },
  },
  methods: {
    handleSelectedTab(tab) {
      this.selectedTab = tab;
      if (this.selectedTab === "dashboard") {
        if (this.$route.name !== "MainDashboard")
          this.$router.push({ name: "MainDashboard" });
      } else if (this.selectedTab === "terminals") {
        if (this.$route.name !== "Terminals")
          this.$router.push({ name: "Terminals" });
      } else if (this.selectedTab === "managers") {
        if (this.$route.name !== "Managers")
          this.$router.push({ name: "Managers" });
      } else if (this.selectedTab === "booking admins") {
        if (this.$route.name !== "BookingAdmin")
          this.$router.push({ name: "BookingAdmin" });
      } else if (this.selectedTab === "pilots") {
        if (this.$route.name !== "Pilots")
          this.$router.push({ name: "Pilots" });
      } else if (this.selectedTab === "fleet") {
        if (this.$route.name !== "Fleets")
          this.$router.push({ name: "Fleets" });
      } else if (this.selectedTab === "leasing"){
        if (this.$route.name !== "LeasingDashboard")
          this.$router.push({name: "LeasingDashboard"})
      }
      else if (this.selectedTab === "routes") {
        if (this.$route.name !== "Routes")
          this.$router.push({ name: "Routes" });
      } else if (this.selectedTab === "trips") {
        if (this.$route.name !== "Trips") this.$router.push({ name: "Trips" });
      } else if (this.selectedTab === "booking") {
        if (this.$route.name !== "Booking")
          this.$router.push({ name: "Booking" });
      }
      else if( this.selectedTab === "manifest"){
        if (this.$route.name !== 'AllManifest'){
          this.$router.push({ name: "AllManifest" });
        }
      }
      else if (this.selectedTab === "settings") {
        if (this.$route.name !== "Settings")
          this.$router.push({ name: "Settings" });
      }

      else if (this.selectedTab === "wallet") {
        if (this.$route.name !== "WalletDashboard")
          this.$router.push({name: "WalletDashboard"});
      }

      sessionStorage.setItem("dashboardComponent", tab);
      this.handleSetCurrentComponent();
    },
    handleSetCurrentComponent() {
      let component = sessionStorage.getItem("dashboardComponent");
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);
    },
    handleLogout() {
      logout();
    },
  },
  created() {
    let component = sessionStorage.getItem("dashboardComponent");
    if (!component) {
      sessionStorage.setItem("dashboardComponent", "dashboard");
      this.handleSetCurrentComponent();
    } else {
      this.selectedTab = component;
      this.$store.dispatch("dashboardComponent/setCurrentComponent", component);

      this.handleSelectedTab(this.selectedTab);
    }
  },
};
</script>

<style scoped lang="scss">
.side-menu {
  display: flex;
  background: #ffffff;
  width: 100%;
  height: available;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 1.5rem 0 0 0 !important;
  flex-direction: column;
}

.side-menu-max{
  max-height: 85vh;
}
.select-div {
  height: 50px;
  background: white;
  width: auto;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  flex-direction: row;
}
.select-div:hover {
  height: 50px;
  background: #f5f7f9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
}

.selected {
  height: 50px;
  background: #f5f7f9;
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
}
</style>
