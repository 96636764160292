<template>
<div>
  <img :src="image" :style="{backgroundColor:color}" alt="profile" v-if="image" class="profile">
  <h6 class="avatar" :style="{backgroundColor:color}" v-else>{{avatar}}</h6>
</div>
</template>

<script>
export default {
  name: "ImageAvatar",
  props:{
    image:[String],
    avatar:{
      type:[String],
      default: "R"
    },
    color:{
      type: [String],
      default: "#004AAD"
    }
  }
}
</script>

<style scoped lang="scss">
.avatar{
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-family: Inter,sans-serif;
  font-style: normal;
}

.profile{
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  opacity: 1;
}
</style>