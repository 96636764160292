<template>
  <v-col sm="12">
    <loader :color="companyColor" v-if="loading" />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div class="tw-flex tw-w-full  tw-flex-col lg:tw-flex-row lg:tw-justify-between">
        <icon-and-text
          :icon="terminalPlus"
          title="New Terminal"
          desc="Add a Terminal"
          :width="$vuetify.breakpoint.mdAndUp ?  '25%' : '100%'"
          @clickAction="newTerminalTab"
        />
        <icon-and-text
          :icon="terminalCash"
          :title="'Total terminals ' + terminals.length.toString()"
          desc=""
          :width="$vuetify.breakpoint.mdAndUp ? '25%' : '100%'"
        />
        <image-and-text-two
          v-if="terminals.length"
          :icon="mostRecent.terminalImage"
          :title="mostRecent.terminalName"
          labelOne="ID :"
          :valueOne="mostRecent.terminalId"
          labelTwo="State :"
          :valueTwo="mostRecent.address.state"
          labelThree="Country :"
          :valueThree="mostRecent.address.country"
          :width="$vuetify.breakpoint.mdAndUp ? '48%' : '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100px' : 'auto'"
        />
      </div>

      <button
        class="tw-flex tw-items-center tw-mt-6 overview-btn"
        @click="routeBack"
        :style="{ color: companyColor }"
      >
        <icons name="arrow-back" :color="companyColor" />
        <span class="tw-ml-4">Terminal Overview</span>
      </button>

      <div
        class="tw-flex tw-w-full  tw-flex-col lg:tw-flex-row tw-justify-between tw-py-5 tw-mt-8 table-container-div tw-px-1 tw-pl-8"
        style="background: white;"
      >
        <div class="table-container">
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <p class="table-header" :style="{ color: companyColor }">
              All Terminals
            </p>
            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
              <div
                class="mr-2"
                style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)"
              >
                <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyColor">
                      mdi-filter-variant
                    </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>

          <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="singleSelect"
            height="available"
            :hide-default-footer="terminals.length <= 10"
            :items="terminals"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            @click:row="viewSingle"
          >
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.terminalImage>
              <span class="status">Image</span>
            </template>
            <template v-slot:[`item.terminalImage`]="{ item }">
              <img
                v-if="item.terminalImage"
                :src="item.terminalImage"
                alt="terminal"
              />

              <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyColor }"
              >
                <avatar :avatar="item.terminalName[0]" :color="companyColor" />
              </div>
            </template>
            <template #header.terminalId>
              <span class="status">Terminal ID</span>
            </template>
            <template v-slot:[`item.terminalId`]="{ item }">
              <span class="td-style text-capitalize">{{ item.terminalId }}</span>
            </template>
            <template #header.terminalName>
              <span class="status">Terminal Name</span>
            </template>
            <template v-slot:[`item.terminalName`]="{ item }">
              <span class="td-style text-capitalize">{{ item.terminalName }}</span>
            </template>
            <template #header.houseNumber>
              <span class="status">House Number</span>
            </template>
            <template v-slot:[`item.houseNumber`]="{ item }">
              <span class="td-style text-capitalize">{{ item.address.houseNumber }}</span>
            </template>
            <template #header.streetName>
              <span class="status">Street Name</span>
            </template>
            <template v-slot:[`item.streetName`]="{ item }">
              <span class="td-style text-capitalize">{{ item.address.streetName }}</span>
            </template>
            <template #header.townCity>
              <span class="status">Town/City</span>
            </template>
            <template v-slot:[`item.townCity`]="{ item }">
              <span class="td-style text-capitalize">{{ item.address.city }}</span>
            </template>
            <template #header.state>
              <span class="status">State</span>
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <span class="td-style text-capitalize">{{ item.address.state }}</span>
            </template>
            <template #header.country>
              <span class="status">Country</span>
            </template>
            <template v-slot:[`item.country`]="{ item }">
              <span class="td-style text-capitalize">{{ item.address.country }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                      @click="selectEditTab(item.id)"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer"
                      @click="openDeleteModal(item.id)"
                    >
                      <img
                        src="@/assets/icons/del-icon.svg"
                        alt=""
                        class="tw-mr-2"
                      />
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
      <delete-modal
        :dialog="dialog"
        @close="closeModal"
        @deleteAction="deleteTerminal"
        :doneLoading="doneLoading"
        description="Are you sure, you want to delete this terminal"
      />
    </v-col>
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import ImageAndTextTwo from "../ImageAndTextTwo";
import terminalPlus from "@/assets/icons/terminal-plus.svg";
import terminalDel from "@/assets/icons/terminal-del.svg";
import terminalCash from "@/assets/icons/terminal-cash.svg";
import locationImg from "@/assets/model.jpg";
import Icons from "../../reuseables/Icons";
import {
  getTransportCompanyTerminals,
  deleteTerminal,
} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import Avatar from "../../reuseables/Avatar";
import DeleteModal from "../../reuseables/DeleteModal";
import dayjs from "dayjs";

export default {
  name: "AllTerminalDashboard",
  components: {
    Loader,
    Icons,
    IconAndText,
    ImageAndTextTwo,
    DeleteModal,
    Avatar,
  },

  data() {
    return {
      dialog: false,
      doneLoading: false,
      terminalPlus,
      terminalDel,
      locationImg,
      terminalCash,
      companyColor: "#004AAD",
      companyData: {},
      terminals: [],
      headers: [
        {text: "",  value:"sno"},
        { text: "", value: "terminalImage" },
        { text: "", value: "terminalName" },
        { text: "", value: "terminalId" },
        { text: "", value: "houseNumber" },
        { text: "", value: "streetName" },
        { text: "", value: "townCity" },
        { text: "", value: "state" },
        { text: "", value: "country" },
        { text: "", value: "actions", align: "end" },
      ],
      not_found: "Terminals will display here",
      selectedRow: [],
      singleSelect: false,
      search: "",
      loading: false,

      mostRecent: {},
      terminalId: "",
    };
  },
  methods: {
    openDeleteModal(id) {
      this.terminalId = id;
      this.dialog = true;
    },
    async deleteTerminal() {
      this.doneLoading = true;
      try {
        const response = await deleteTerminal(
          this.companyData.id,
          this.terminalId
        );
        console.log(response);
        this.$displaySnackbar({
          message: response.data.details,
          success: true,
        });
        this.doneLoading = false;
        await this.getAllTerminals();
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
        this.doneLoading = false;
        this.closeModal();
        console.log(error.response);
      }
    },
    closeModal() {
      this.dialog = !this.dialog;
      this.terminalId = "";
    },
    selectEditTab(id) {
      console.log(id);
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "editTerminal"
      );
      sessionStorage.setItem("terminalComponent", "editTerminal");
      sessionStorage.setItem("slugId", id);
    },
    routeBack() {
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "terminalDashboard"
      );
      sessionStorage.setItem("terminalComponent", "terminalDashboard");
    },
    viewSingle(terminal) {
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "singleTerminal"
      );
      sessionStorage.setItem("terminalComponent", "singleTerminal");
      sessionStorage.setItem("slugId", terminal.id);
    },
    newTerminalTab() {
      this.$store.dispatch(
        "terminalComponent/setCurrentComponent",
        "addTerminal"
      );
      sessionStorage.setItem("terminalComponent", "addTerminal");
    },
    async getAllTerminals() {
      this.loading = true;
      await getTransportCompanyTerminals(this.companyData.id)
        .then((res) => {
          this.terminals = res.data;
          this.mostRecent = this.terminals[this.terminals.length - 1];

          this.terminals.sort(function (a, b){
            return  dayjs(b.created).toDate() - dayjs(a.created).toDate()
          })
          this.terminals.forEach((terminal,index) => terminal.sno=index+1)
          this.loading = false;

        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response);
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          });
        });
    },
  },

  async created() {
    this.loading = true;
    sessionStorage.removeItem("slugId");
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllTerminals();
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.showings {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;

  span {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  // text-align: left;
  color: rgba(0, 74, 173, 1);
}

.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0 !important;
}
.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  min-height: 63vh;
  .table-container {
    width: 100%;
    padding-right: 30px;
    background: #fdfffc;
    .table-container-header {
      font-family: "Inter", serif !important;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0;
      text-align: left;
      color: #004aad;
    }
  }
  .no-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    p {
      font-family: "Inter", serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0;
      margin-bottom: 0 !important;
      color: rgba(255, 255, 255, 1);
    }
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins",sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}
</style>
